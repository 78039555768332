import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useRealmApp } from "./realm_app";
import OnboardingNavbar from "./onboarding_navbar";
import { Footer } from "./footer";
import "../index.css";

function sleep(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export default ({ home_uri }) => {
  const realmApp = useRealmApp();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  function handleEditPassword(newVal) {
    setPassword(newVal);
    // Modify backend
  }
  async function handleResetPassword(event) {
    event.preventDefault();
    setLoading("resetting...");

    if (typeof window !== `undefined`) {
      const searchParams = new URL(location.href).searchParams;
      const token = searchParams.get("token");
      const tokenId = searchParams.get("tokenId");
      console.log([token, tokenId]);

      // Modified the password reset error message  - Lidia Delgado, Nov. 28, 2021
      const errorMsg =
        "Something went wrong. Please try again, or try clicking on the reset link in your email again. ";

      if (token != null && tokenId != null) {
        try {
          await realmApp.app.emailPasswordAuth.resetPassword(
            token,
            tokenId,
            password
          );
          setLoading(
            "Your password has been reset. " +
              "Redirecting you home in 5 seconds..."
          );
          await sleep(5000);
          navigate(home_uri);
        } catch (err) {
          console.log(err);
          console.log(JSON.stringify(err));
          setLoading(errorMsg);
        }
      } else {
        setLoading(errorMsg);
      }
    } else {
      setLoading("Page load error. Please refresh to try again.");
    }
  }
  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <div className="page-content-center">
          <h3>Reset Password</h3>
        </div>
        <div className="page-content-center">
          <div className="page-content page-content-small">
            <br />
            <form
              onSubmit={handleResetPassword}
              className="page-content page-content-small"
            >
              <div>
                <strong>NEW PASSWORD</strong>
                <br />
                <input
                  type="password"
                  style={{ display: "table-cell", width: "100%" }}
                  value={password}
                  onChange={(e) => handleEditPassword(e.target.value)}
                />
              </div>
              <br />
              <Button className="light-green-button btn-block" type="submit">
                Reset
              </Button>
            </form>
          </div>
        </div>
        <br />
        <div className="page-content-center">
          <Link to={home_uri}>Go home</Link>
        </div>
        <br />
        <div className="page-content-center">{loading}</div>
      </div>
      <Footer />
    </>
  );
};
